import React from 'react'
import "../../assets/custom/style.css"

function Header() {
  return (
    <>
      <div className="header bg-gradient-prosper pb-8 pt-5 pt-md-8"></div>
    </>
  )
}

export default Header
