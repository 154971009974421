/*eslint-disable*/
import React from "react";
import { withTranslation } from "react-i18next";
// reactstrap components
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

function Footer(props) {
	const { t } = props;
	return (
		<footer className="footer">
			<Row className="align-items-center justify-content-xl-between">
				<Col xl="6">
					<div className="copyright text-center text-xl-left text-muted">
						© {t("2023-24")}{" "}
						<a
							className="font-weight-bold ml-1"
							href="https://prospertech.io/"
							rel="noopener noreferrer"
							target="_blank"
						>
							Prosper Technologies
						</a>
					</div>
				</Col>

				<Col xl="6">
					<Nav className="nav-footer justify-content-center justify-content-xl-end">
						<NavItem>
							<NavLink
								href="https://prospertech.io/"
								rel="noopener noreferrer"
								target="_blank"
							>
								Prosper Technologies
							</NavLink>
						</NavItem>

						<NavItem>
							<NavLink
								href="https://prospertech.io/#info"
								rel="noopener noreferrer"
								target="_blank"
							>
								{t("About Us")}
							</NavLink>
						</NavItem>
					</Nav>
				</Col>
			</Row>
		</footer>
	);
}

export default withTranslation()(Footer);
